<template>
  <div class="layout">
      <div class="box">
          <div class="layout-rokid">
            <p class="rokid-name" style="margin-bottom: .45rem">PC、手机网页端访问地址<a href="https://lingyun.ustep.cn/" target="_blank">https://lingyun.ustep.cn/</a></p>
            <p class="rokid-name">眼镜端扫描下方二维码下载</p>
            <div class="rokid-code"><vue-qr :logoSrc="logoSrc" :text="QRCode" :size="500" class="layout-code"></vue-qr></div>
          </div>
          <div class="print"><font class="name">安卓手机端APP下载</font><br/>如下载无反应，请使用浏览器打开下载！</div>
          <div class="layout-btn">
              <Button type="primary" size="large" long :loading="loadingStatus" @click="onDownLoadAndroidLink">
                  <span v-if="!loadingStatus">Android 下载</span>
                  <span v-else>Android 下载</span>
              </Button>
          </div>
      </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Button, Message } from "iview";
import { getRokidQRCodeService,getAndroidLinkService } from "@/service/user-service";
Vue.component('Button', Button);
import vueQr from 'vue-qr'
export default {
    name: "downLoad",
    components:{
        vueQr
    },
    data() {
        return {
            loadingStatus:false,
            QRCode:'',
            logoSrc:require("@/assets/logo-img.png"),
            codeVisible:false,
        }
    },
    created() {
        this.onDownLoadQRCode().then()
    },
    mounted() {
      document.title = '软件下载'
    },
    methods:{
          /**
           * 下载链接 code
           * @returns {Promise<void>}
           */
          async onDownLoadQRCode() {
              try {
                  let params = {
                      version:0,
                      platform:'lingyun_ar'
                  }
                  const { data,code }  = await getRokidQRCodeService(params);
                  if(code === 'SUCCESS'){
                      let obj = {"T":"APP","P":"com.yb.lingyun","N":"lingyun","V":data.version,"U":data.downloadUrl}
                      this.QRCode = JSON.stringify(obj)
                  }else{
                      Message.error('获取失败！')
                  }

                  this.loadingStatus = false
              }catch (error) {
                  console.log(error)
              }
          },
          /**
           * 下载链接 android
           * @returns {Promise<void>}
           */
          async onDownLoadAndroidLink() {
            try {
              this.loadingStatus = true
              let params = {
                version:0,
                platform:'lingyun_webview'
              }
              const { data,code }  = await getAndroidLinkService(params);
              if(code === 'SUCCESS'){
                window.location.href=data.downloadUrl
              }else{
                Message.error('获取失败！')
              }
              this.loadingStatus = false
            }catch (error) {
              this.loadingStatus = false
              console.log(error)
            }
          }
    }
};
</script>

<style lang="less">
.layout{
    display: flex;
    width:7.5rem;
    margin: 0 auto;
    height:100%;
    align-items: center;

   .layout-rokid{
       float:left;
       width:7.5rem;
       padding:0 1.5rem;
       text-align: center;

       .rokid-code{
           float:left;
           width:4.5rem;
           height:4.5rem;
           background: #ebebeb;
           margin-bottom: .6rem;

           .layout-code{
               float:left;
               width:100%;
               height:100%;
           }
       }
       .rokid-name{
           float:left;
           width:100%;
           text-align: center;
           font-size: .3rem;
           color:#333;
       }
   }
    .print{
        float:left;
        width:100%;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
        color: #333;

        .name{
            font-size: .3rem;
        }
    }
   .layout-btn{
       float:left;
       width:7.5rem;
       padding:0 2rem;
   }
}
</style>
